<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-s-data"></i> 数据统计
        </el-breadcrumb-item>
        <el-breadcrumb-item>按月统计</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-card shadow="always">
      <el-row :gutter="5">
        <el-button @click="handleGoBack" plain icon="el-icon-back" size="mini">
          返回上一页</el-button
        >
        <el-button
          @click="handleExport"
          plain
          type="warning"
          icon="el-icon-download"
          size="mini"
        >
          导出
        </el-button>
        <el-button
          @click="handleReplacementData"
          plain
          icon="el-icon-position"
          size="mini"
          type="danger"
        >
          补发数据
        </el-button>
      </el-row>
    </el-card>
    <el-card shadow="always">
      <el-row :gutter="5">
        <div class="block" style="margin-right: 1%">
          <span class="demonstration">选择月份 </span>
          <el-date-picker
            @change="handleDateChange"
            v-model="date"
            type="month"
            size="mini"
            placeholder="选择月份"
          >
          </el-date-picker>
        </div>
      </el-row>
    </el-card>
    <el-card shadow="always">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column type="index" :index="1"> </el-table-column>
        <el-table-column prop="date" label="日期" min-width="90px">
          <template #default="scope">
            <div v-if="scope.row.date === '1999-04-27 00:00:00'">月度累计</div>
            <div v-else>
              {{ new Date(scope.row.date).format("yyyy-MM-dd") }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="实时数据">
          <el-table-column prop="realData.currentCount" label="实收">
          </el-table-column>
          <el-table-column prop="realData.theoryCount" label="应收">
          </el-table-column>
          <el-table-column
            prop="realData.integrityRate"
            label="完整率"
            sortable
          >
          </el-table-column>
        </el-table-column>
        <el-table-column label="分钟数据">
          <el-table-column prop="minuteData.currentCount" label="实收">
          </el-table-column>
          <el-table-column prop="minuteData.theoryCount" label="应收">
          </el-table-column>
          <el-table-column
            prop="minuteData.integrityRate"
            label="完整率"
            sortable
          >
          </el-table-column>
        </el-table-column>
        <el-table-column label="小时数据">
          <el-table-column prop="hourData.currentCount" label="实收">
          </el-table-column>
          <el-table-column prop="hourData.theoryCount" label="应收">
          </el-table-column>
          <el-table-column
            prop="hourData.integrityRate"
            label="完整率"
            sortable
          >
          </el-table-column>
        </el-table-column>
        <el-table-column label="日数据">
          <el-table-column prop="dayData.currentCount" label="实收">
          </el-table-column>
          <el-table-column prop="dayData.theoryCount" label="应收">
          </el-table-column>
          <el-table-column prop="dayData.integrityRate" label="完整率" sortable>
          </el-table-column>
        </el-table-column>
      </el-table>
    </el-card>
    <set-reissue-data-dialog
      :dialog-visible="dialogVisibleSetReissueData"
      @updateVisible="closeSetReissueData"
    >
    </set-reissue-data-dialog>
  </div>
</template>

<script>
import router from "@/router";
import { getMonthTable } from "@/api/statistic";
import { exportStatisticMonth } from "@/api/export";
import SetReissueDataDialog from "@/views/room/countercharge/dialog/SetReissueDataDialog";

export default {
  name: "StatisticMonth",
  components: { SetReissueDataDialog },
  data() {
    return {
      date: new Date(),
      dialogVisibleSetReissueData: false,
      tableData: [],
    };
  },

  created() {
    this.getList();
  },
  methods: {
    handleExport() {
      const mn = this.$route.query.mn;
      const params = {
        dateTime: new Date(this.date).getTime(),
        mn: mn,
      };
      exportStatisticMonth(params);
    },
    handleGoBack() {
      router.go(-1);
      this.$store.commit("closeCurrentTag", {
        $router: this.$router,
        $route: this.$route,
      });
    },
    handleDateChange(val) {
      this.date = new Date(val);
      this.getList();
    },
    // 补发数据
    handleReplacementData() {
      this.dialogVisibleSetReissueData = true;
    },
    closeSetReissueData() {
      this.dialogVisibleSetReissueData = false;
    },
    getList() {
      const mn = this.$route.query.mn;
      const param = {
        date: new Date(this.date).getTime(),
        mn: mn,
      };
      console.log("请求参数:", JSON.stringify(param));
      getMonthTable(param).then((result) => {
        console.log(JSON.stringify(result));
        const data = result.data;
        this.tableData = data.list;
      });
    },
  },
};
</script>

<style scoped></style>
