import request from "@/utils/request";

// 获取table
export function getRoomTable(params) {
  return request({
    url: "/room",
    method: "Get",
    params: params,
  });
}
// 补发数据
export function SetReissueData(params, requestBody) {
  return request({
    url: "/countercharge/reissue-data",
    method: "Post",
    params: params,
    data: requestBody,
  });
}
