<template>
  <div>
    <el-dialog
      v-model="visible"
      @open="onOpen"
      @close="onClose"
      title="补发数据"
    >
      <el-form
        ref="elForm"
        :model="formData"
        size="mini"
        label-width="px"
        label-position="left"
      >
        <el-form-item label-width="80px" label="类型选择" prop="roles">
          <el-select
            @change="handleTypeChange"
            v-model="this.select.type"
            placeholder="请选择补发数据类型"
            :style="{ width: '100%' }"
          >
            <el-option
              v-for="(item, index) in rolesOptions"
              :key="index"
              :label="item.label"
              :value="item.value"
              :disabled="item.disabled"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label-width="80px" label="开始时间" prop="beginDate">
          <el-date-picker
            @change="handleBeginDateChange"
            v-model="beginDate"
            type="datetime"
            size="mini"
            placeholder="开始时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label-width="80px" label="结束时间" prop="endDate">
          <el-date-picker
            @change="handleEndDateChange"
            v-model="endDate"
            type="datetime"
            size="mini"
            placeholder="结束时间"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div>
        <slot name="footer">
          <el-button @click="close">取消</el-button>
          <el-button type="primary" @click="handleConfirm">确定</el-button>
        </slot>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { SetReissueData } from "@/api/room";

export default {
  name: "SetReissueDataDialog",
  components: {},
  props: {
    dialogVisible: Boolean,
  },
  data() {
    return {
      visible: false,
      params: {},
      requestBody: {},
      beginDate: new Date(new Date(new Date().toLocaleDateString()).getTime()),
      endDate: new Date(),
      formData: {
        roles: undefined,
      },
      select: { type: "minute" },
      rolesOptions: [
        {
          label: "分钟数据",
          value: "minute",
        },
        {
          label: "小时数据",
          value: "hour",
        },
        {
          label: "日数据",
          value: "day",
        },
      ],
    };
  },
  computed: {},
  watch: {
    dialogVisible: {
      handler(value) {
        this.visible = value;
      },
    },
  },
  created() {},
  mounted() {
    this.visible = this.dialogVisible;
  },
  methods: {
    onOpen() {},
    onClose() {
      this.$refs["elForm"].resetFields();
      this.$emit("updateVisible");
    },
    close() {
      this.$emit("updateVisible");
    },
    handleBeginDateChange(val) {
      this.beginDate = new Date(val);
    },
    handleEndDateChange(val) {
      this.endDate = new Date(val);
    },
    handleTypeChange(val) {
      console.log(val);
    },
    handleConfirm() {
      const monitoryPointName = this.$route.query.monitoryPointName;
      const params = {
        monitoryPointName: monitoryPointName,
      };
      const requestBody = {
        type: this.select.type,
        begin: new Date(this.beginDate).format("yyyy-MM-dd hh:mm:ss"),
        end: new Date(this.endDate).format("yyyy-MM-dd hh:mm:ss"),
      };
      console.log(params, requestBody);
      SetReissueData(params, requestBody);
      this.close();
    },
  },
};
</script>
<style></style>
